<template>
  <div class="text-left">
    <BaseHeader :title="'Not Found'"></BaseHeader>

    <div class=" my-auto">
        <!-- Content area -->
        <div
          class="content justify-content-center align-items-center d-flex"
        >
          <!-- Container -->
          <div class="flex-fill">
            <!-- Error title -->
            <div class="text-center mb-3">
              <h1 class="error-title">404</h1>
              <h5>Oops, an error has occurred. Not found!</h5>
            </div>
            <!-- /error title -->

            <!-- Error content -->
            <div class="row">
              
                <!-- Buttons -->
                <div class="row ">
                  <div class="col-12 text-center">
                    <a
                      href="/home"
                      class="btn bg-primary rounded-0 "
                      ><i class="icon-home4 mr-2"></i> Back Home</a
                    >
                  </div>

                  
                </div>
                <!-- /buttons -->
             
            </div>
            <!-- /error wrapper -->
          </div>
          <!-- /container -->
        </div>
        <!-- /content area -->
      </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
};
</script>
